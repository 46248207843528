<script>
/**
 * DataRow is an element that displays a key value pair in a defined proportion.
 * - messageType (default: default): Message types will add a colored border on the left side of the element. Possible values are: default (no border), info, success, warning, error.
 * - label (required): The text displayed as label on the left side of the data row.
 * - value (required): The text displayed as value on the right side of the data row.
 * - tooltip: The text displayed on hover. If provided the rule for the tooltip described in 'noLineBreak' will no longer work.
 * - isClickableValue (default: false): If true, the value will throw a value-click event on click
 * - labelWidthPercentage (default: 50): The ratio of the width of label and value.
 * - isSeparatedByBottomBorder (default: false): If true, multiple data rows will be separated by a 1px bottom border. The last data row will never have the border.
 * - noLineBreak (default: false): If true, there will be no linebreaks. Value ends with dots if it is too long and will get a tooltip based on its value.
 **/
export default {
    name: 'DataRow',
    components: {},
    // @value-click: Event emitted on click
    emits: ['value-click'],
    props: {
        messageType: {
            default: 'default',
            type: String
        },
        label: String,
        value: [String, Number, Date],
        tooltip: String,
        isClickableValue: {
            default: false,
            type: Boolean
        },
        labelWidthPercentage: {
            default: 50,
            type: Number
        },
        isSeparatedByBottomBorder: {
            default: false,
            type: Boolean
        },
        noLineBreak: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
        }
    },
    methods: {
        emitClick (event) {
            if (this.isClickableValue) {
                event.stopPropagation()
                this.$emit('value-click', this.value)
            }
        }
    },
    computed: {
        getTooltip () {
            if (this.tooltip) {
                return this.tooltip
            }
            return this.noLineBreak
                ? this.value
                : null
        }
    }
}
</script>

<template>
    <div class="c_data-row-wrapper"
         v-bind:class="['m--' + messageType, {'m--bottom-border': isSeparatedByBottomBorder}]">
        <div class="c_data-row-label"
             v-bind:style="{width:labelWidthPercentage + '%'}">
            <span>{{label}}</span>
        </div>
        <div v-if="labelWidthPercentage < 100"
             class="c_data-row-value"
             v-bind:class="{
                'generals-text-ellipsis': noLineBreak,
                'm--clickable': isClickableValue
             }"
             v-bind:style="{width:100 - labelWidthPercentage + '%'}"
             v-bind:title="getTooltip"
             v-on:click="emitClick($event)"
             v-dompurify-html="value">
        </div>
    </div>
</template>

<style lang="less">
.c_data-row-wrapper {
    min-height: var(--data-row-height);
    height: auto;
    border-left: 4px solid transparent;
    overflow: auto;

    &.m--info {
        border-left-color: var(--color-info);
        margin-bottom: 1px;
    }

    &.m--success {
        border-left-color: var(--color-success);
        margin-bottom: 1px;
    }

    &.m--warning {
        border-left-color: var(--color-warning);
        margin-bottom: 1px;
    }

    &.m--error {
        border-left-color: var(--color-error);
        margin-bottom: 1px;
    }

    &.m--bottom-border:not(:last-child) {
        border-bottom: 1px solid var(--color-border-light);
    }

    .c_data-row-label, .c_data-row-value {
        cursor: default;
        float: left;
        padding: 14px 12px;
    }

    .c_data-row-label {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .c_data-row-value {

        &.m--clickable {
            color: var(--color-link);

            &:hover {
                cursor: pointer;
                color: var(--color-link-active);
            }
        }
    }
}
</style>
