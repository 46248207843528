/**
 * The locale service of the application.
 * Message properties can be used in a view by calling $t('messageProperty') or $tc('messageProperty').
 * Note:
 *  - $t returns a MessageObject. To be type safe, it is recommended to use $tc, because it always returns a string. Otherwise, this can lead to problems if you want to assign a message property to a string property.
 *  - If parameters are to be passed to the message property ($t('path.to.message.property', [param1])), $t must always be used, otherwise the parameters will not be resolved. Here also always a string is generated.
 * You can use '@:messagePropertyName if you want to reuse a property or relate to one that is already existing.
 * Specify variables within a message by using {0}, {1}, ...
 * When using a message property with variables in a view, fill the gaps with a list of values e.g. $t('messageProperty', [first, second, ...]).
 * Make sure that you always keep the same structure of message properties for each available language!
 **/
import { createI18n } from 'vue-i18n'
import de from '@/i18n/de'
import en from '@/i18n/en'

const datetimeFormats = {
    de: de.dateTimeFormats,
    en: en.dateTimeFormats
}

const messages = {
    de: de.messages,
    en: en.messages
}

const language = window.navigator.language.substring(0, 2) === 'de'
    ? 'de'
    : 'en'

export const i18n = createI18n(
    {
        locale: language,
        fallbackLocale: 'en',
        messages,
        datetimeFormats,
        warnHtmlInMessage: 'off', // was disabled per default in older versions,
        legacy: true
    }
)

export const { t: $t, tc: $tc, d: $d } = i18n.global
