<script>
/**
 * Accordion is an expandable container which contains metadata.
 * - title: The accordion title will be displayed in the header of the accordion.
 * - count: Show a counter badge next to the title of the accordion.
 * - items: List of key value pairs.
 * - useCustomContent (default: false): Use this instead of providing items. It will generate a slot in which you can place custom html.
 * - expandedByDefault (default: false): Determines whether the accordion is expanded when rendered.
 * - colorCode: Color hex code which will define the background-color of the accordion heading.
 * - isDisabled (default: false): Accordion is expanded and cannot be collapsed.
 **/
import DataRow from '@/components/DataRow.vue'
import { urlHelper } from '@/mixins/urlHelper'

export default {
    name: 'Accordion',
    components: {
        DataRow
    },
    props: {
        title: String,
        count: [Number, String],
        items: Array,
        useCustomContent: {
            default: false,
            type: Boolean
        },
        expandedByDefault: {
            default: false,
            type: Boolean
        },
        colorCode: String,
        isDisabled: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            openUrlForValue: urlHelper.methods.openUrlForValue,
            isUrlValue: urlHelper.methods.isUrlValue,
            isAccordionExpanded: this.expandedByDefault || this.isDisabled,
            defaultItemHeight: 50,
            tolerance: 15
        }
    },
    methods: {
        toggleAccordion () {
            this.isAccordionExpanded = !this.isAccordionExpanded
        }
    }
}
</script>

<template>
    <div class="c_accordion">
        <div class="c_accordion-header"
             v-bind:class="{'m--disabled': isDisabled}"
             v-bind:style="{'border-color': colorCode || 'transparent'}"
             v-on:click="toggleAccordion()">
            <span>{{title}}</span>
            <span v-if="count"
                  class="c_accordion-header-badge">{{count}}</span>
            <span v-if="!isDisabled"
                  class="c_accordion-header-toggle"
                  v-bind:class="[isAccordionExpanded ? 'fas fa-caret-down' : 'fas fa-caret-left']"></span>
        </div>
        <div class="c_accordion-body"
             v-bind:class="{'m--expanded': isAccordionExpanded}">
            <transition-group name="show">
                <template v-if="isAccordionExpanded">
                    <slot v-if="useCustomContent"></slot>
                    <template v-else>
                            <DataRow v-for="item in items"
                                     v-bind:key="item.label"
                                     class="c_accordion-item"
                                     v-bind:label="item.label"
                                     v-bind:value="item.value"
                                     v-bind:label-width-percentage=40
                                     v-bind:is-separated-by-bottom-border=false
                                     v-bind:is-clickable-value="item.isClickableValue && isUrlValue(item.value)"
                                     @value-click="item.isClickableValue ? openUrlForValue(item.value) : null"
                            >
                            </DataRow>
                    </template>
                </template>
            </transition-group>
        </div>
    </div>
</template>

<style lang="less">
.c_accordion {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    &:not(:first-child) {
        margin-top: 5px;
    }

    .c_accordion-header {
        position: relative;
        width: 100%;
        height: var(--accordion-head-height);
        background-color: var(--color-background-light);
        padding: 12px var(--container-spacing) 12px 14px;
        border-left: 4px solid transparent;
        font-family: "Source Sans Pro Bold", sans-serif;

        &.m--disabled {
            pointer-events: none;
        }

        &:hover {
            cursor: pointer;
            background-color: var(--color-background-mid);
        }

        .c_accordion-header-badge {
            position: absolute;
            font-family: "Source Sans Pro", sans-serif;
            height: 25px;
            margin: -2px 0 0 12px;
            font-size: 16px;
            text-align: center;
            background-color: var(--color-background-mid);
            padding: 2px 8px;
            border-radius: 15px;
        }

        .c_accordion-header-toggle {
            float: right;
            margin-top: 3px;
        }
    }

    .c_accordion-body {
        position: relative;
        width: 100%;
        height: 0;

        &.m--expanded {
            height: auto;
        }

        .c_accordion-item {
            position: relative;
            width: 100%;
        }
    }
}
</style>
