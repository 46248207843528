<script>
/**
 * ContentHeader is an element that can be used as a headline for a part of the website. It consists of a headline and a right floating area, where buttons or inputs can be placed.
 * - headerTitle (required): The displayed title.
 * - headerSubTitle (optional): The provided text will be displayed next to the headerTitle. They will be separated by a '|'.
 * - headerCount (optional): The provided number will be displayed in a circle element next the headerTitle or headerSubTitle.
 * - linkConfig (optional): Object consists of a target and tooltip. If provided, the headerTitle will be clickable, redirecting to the specified target.
 * - goBackTarget (optional): If the target is provided, there will be a caret on the left of the headerTitle, which redirects to the specified target.
 * - useSlot (default: false): Generate the slot used for the right floating space, where inputs and buttons can be placed.
 * - isPrimaryHeader (default: false): Determines whether the header has a non-transparent background and padding.
 **/
import RouteLink from '@/components/RouteLink.vue'

export default {
    name: 'ContentHeader',
    components: {
        RouteLink
    },
    props: {
        headerTitle: String,
        headerSubTitle: String,
        headerCount: [Number, String],
        linkConfig: Object,
        goBackTarget: Object,
        useSlot: {
            default: false,
            type: Boolean
        },
        isPrimaryHeader: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<template>
    <div class="c_page-content-header-wrapper"
         v-bind:class="{'m--primary': isPrimaryHeader}">
        <div class="c_page-content-header-headline generals-text-ellipsis"
             v-bind:class="{'m--hasAddons': useSlot}">
            <router-link v-if="goBackTarget"
                         class="c_page-content-header-back fas fa-arrow-left"
                         v-bind:to="goBackTarget">
                <span>{{goBackTarget.title || $tc('contentHeader.goBackTitleDefault')}}</span>
            </router-link>
            <div class="c_page-content-header-title"
                 v-bind:class="{'m--subLine': goBackTarget}">
                <RouteLink v-if="linkConfig"
                           v-bind:displayed-text="headerTitle"
                           v-bind:target="linkConfig.target"
                           v-bind:tooltip="linkConfig.tooltip">
                </RouteLink>
                <span v-else>{{headerTitle}}</span>
                <span v-if="headerSubTitle"
                      v-bind:title="headerSubTitle"
                      class="c_page-content-header-subtitle">{{headerSubTitle}}</span>
                <span v-if="headerCount"
                      class="c_page-content-header-headline-badge generals-badge-count">{{headerCount}}</span>
            </div>
        </div>
        <div v-if="useSlot"
             class="c_page-content-header-addons">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="less">
.c_page-content-header-wrapper {
    display: flex;
    width: 100%;
    height: var(--headline-height);

    &.m--primary {
        height: var(--primary-headline-height);
        padding: 15px var(--container-spacing);
        background-color: var(--color-background-light);

        .c_page-content-header-addons {
            background-color: transparent;
        }

        .generals-input-container .generals-input {
            border: 1px solid var(--color-border-almost-white);
            border-bottom-color: var(--color-border-dark);
        }
    }

    .c_page-content-header-headline {
        font-size: 25px;
        padding: 2px 0;

        &.m--hasAddons {
            margin-right: var(--container-spacing);
        }

        .c_page-content-header-back {
            position: absolute;
            top: 13px;
            color: var(--color-text-mid);
            font-size: 15px;

            &:hover {
                color: var(--color-text-highlighted);
            }

            span {
                font-family: "Source Sans Pro", sans-serif;
                margin-left: 4px;
            }
        }

        .c_page-content-header-title {
            font-family: "Source Sans Pro Bold", sans-serif;

            &.m--subLine {
                margin-top: 8px;
            }
        }

        .c_page-content-header-subtitle {
            font-family: "Source Sans Pro", sans-serif;
            border-left: 2px solid var(--color-border-dark);
            margin-left: 15px;
            padding-left: 15px;
        }

        .c_page-content-header-headline-badge {
            margin-top: 4px;
        }
    }

    .c_page-content-header-addons {
        flex: 1 0 auto;
        background-color: var(--color-background-default);

        * {
            float: right;

            &:not(:last-child) {
                margin-left: var(--container-spacing);
            }
        }
    }
}
</style>
