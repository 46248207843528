<script>
/**
 * ModalContainer is a pre styled modal overlay fading in from the right border of the screen. It has a customizable body providing a slot.
 * The slot's content can be defined for every instance of the modal container. The components emit a @modal-close event when losing focus or pressing the x button in the header.
 * - modalTitle: Text displayed as title in the modal header.
 * - modalSubtitle: Text displayed as subtitle in the modal header.
 * - isExpanded (default: false): Make the container appear or disappear
 * - disableScreen (default: false): If true, the rest of the ui will be disabled, due to an overlay.
 * - modalWidthPercent (default: 50): Define the width in percent of the modal container.
 **/

import ContentHeader from '@/components/ContentHeader.vue'
import IconButton from '@/components/IconButton.vue'

export default {
    name: 'ModalContainer',
    components: {
        ContentHeader,
        IconButton
    },
    // @modal-close: Event emitted when clicking the x button in the header and on blur
    emits: ['modal-close'],
    props: {
        modalTitle: String,
        modalSubtitle: String,
        isExpanded: {
            default: false,
            type: Boolean
        },
        disableScreen: {
            default: false,
            type: Boolean
        },
        modalWidthPercent: {
            default: 50,
            type: Number
        }
    },
    methods: {
        keyHandler (event) {
            if (event.key === 'Escape') {
                this.closeModalContainer()
            }
        },

        closeModalContainer () {
            this.$emit('modal-close')
        }
    },
    computed: {
        getModalTitle () {
            return this.modalTitle || this.$tc('modalContainer.defaultHeaderTitle')
        },

        getModalSubTitle () {
            return this.modalSubtitle || null
        }
    },
    watch: {
        isExpanded (isExpandedState) {
            if (isExpandedState === true) {
                this.$el.focus({ preventScroll: true })
            }
        }
    }
}
</script>

<template>
    <div class="c_modal-wrapper"
         tabindex="0"
         v-on:keyup="keyHandler($event)">
        <div v-if="disableScreen && isExpanded"
             class="c_modal-background">
        </div>
        <div class="c_modal-container"
             v-bind:style="{width: isExpanded ? `${modalWidthPercent}%` : 0}"
             v-bind:class="{
                'm--full-height': disableScreen,
                'm--expanded': isExpanded
             }">
            <div class="c_modal-container-header">
                <ContentHeader v-bind:header-title="getModalTitle"
                               v-bind:header-sub-title="getModalSubTitle"
                               v-bind:is-primary-header=true
                               v-bind:use-slot="true">
                    <template v-slot>
                        <IconButton icon-class="fas fa-times"
                                    @button-submit="closeModalContainer()">
                        </IconButton>
                    </template>
                </ContentHeader>
            </div>
            <div class="c_modal-container-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_modal-wrapper {

    .c_modal-background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51,51,51, 0.75);
        z-index: var(--z-index-modal-background);
    }

    .c_modal-container {
        position: fixed;
        height: calc(100% - var(--main-navigation-height));
        top: var(--main-navigation-height);
        right: 0;
        background-color: var(--color-background-default);
        overflow: hidden;
        opacity: 0;
        box-shadow: 0 6px 10px 0 var(--color-border-dark);
        transition: opacity 0.4s ease, width 0.9s ease;
        z-index: var(--z-index-modal-container);

        &.m--full-height {
            height: 100%;
            top: 0;
            z-index: var(--z-index-modal-background);
        }

        &.m--expanded {
            opacity: 1;
            transition: opacity 1s ease, width 0.5s ease;
        }

        .c_modal-container-header {
            height: var(--primary-headline-height);
        }

        .c_modal-container-body {
            height: calc(100% - var(--primary-headline-height));
            padding: var(--container-spacing);
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
</style>
