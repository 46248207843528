<script>
/**
 * Icon will display an icon with a tooltip and several states.
 * - tooltip (optional): The text displayed as tooltip
 * - iconClass (required): A font awesome icon class. If no class is provided, a question mark icon will be displayed as default.
 * - iconType (default: default): The type of the icon. Different type result in different icon colors: Possible values are: default, inactive (like disabled), info, success, warning, error, dark, bright.
 * - iconGlow (default: false): Determines, whether there is a colored shadow around the icon based on the iconType.
 * - iconLarge (default: false): Determines, whether the icon is displayed in a larger font size
 **/
export default {
    name: 'Icon',
    props: {
        tooltip: String,
        iconClass: {
            default: 'fas fa-question-circle',
            type: String
        },
        iconType: {
            default: 'default',
            type: String
        },
        iconGlow: {
            default: false,
            type: Boolean
        },
        iconLarge: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<template>
    <div class="c_icon-wrapper"
         v-bind:class="{'m--large': iconLarge}"
         v-bind:title="tooltip">
        <span v-if="iconType === 'inactive'"
              class="c_icon m--inactive"
              v-bind:class="[iconClass]">
        </span>
        <span v-else
              class="c_icon"
              v-bind:class="['m--' + iconType, iconClass, {'m--glowing': iconGlow}]">
        </span>
    </div>
</template>

<style lang="less">
.c_icon-wrapper {
    width: var(--icon-width);
    font-size: 17px;
    text-align: center;
    padding: 0 8px;

    &.m--large {
        font-size: 20px;
    }

    .c_icon {
        width: 100%;
        padding-top: 4px;
        cursor: default;

        &.m--default {
            color: var(--color-icon);
        }

        &.m--inactive {
            color: var(--color-icon-inactive);
        }

        &.m--info {
            color: var(--color-info);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-info);
            }
        }

        &.m--success {
            color: var(--color-success);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-success);
            }
        }

        &.m--warning {
            color: var(--color-warning);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-warning);
            }
        }

        &.m--error {
            color: var(--color-error);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-error);
            }
        }

        &.m--dark {
            color: var(--color-text-dark);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-text-dark);
            }
        }

        &.m--bright {
            color: var(--color-text-bright);

            &.m--glowing {
                text-shadow: 0 0 8px var(--color-text-bright);
            }
        }
    }
}
</style>
