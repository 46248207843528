/**
 * A reusable service for rest requests. Provides functions for get, post, put, delete.
 * Can handle the error and success notifications if an errorMessage and/or successMessagesObject is passed as a parameter.
 * Returns the success or error response data object as promise.
 *
 * @property {String} messageCode
 **/
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'

export const axiosService = {
    mixins: [
        userHandler,
        notificationHandler
    ],
    methods: {
        axiosGet (api, errorMessage, successMessagesObject, isExternalApi, config, returnFullResponse) {
            const url = isExternalApi
                ? api
                : `${this.$global.backendPath}/${api}`

            this.$global.pendingRequests++
            return this.axios.get(url, config)
                .then(successResponse => {
                    this.$global.pendingRequests--
                    addSuccessNotification(this, successMessagesObject)
                    const response = returnFullResponse
                        ? successResponse
                        : successResponse.data
                    return Promise.resolve(response)
                })
                .catch(error => {
                    this.$global.pendingRequests--
                    addErrorNotification(this, error, errorMessage)
                    return Promise.reject(error?.response)
                })
        },

        axiosPost (api, body, errorMessage, successMessagesObject, isExternalApi, config) {
            const url = isExternalApi
                ? api
                : `${this.$global.backendPath}/${api}`

            this.$global.pendingRequests++
            return this.axios.post(url, body, config)
                .then(successResponse => {
                    this.$global.pendingRequests--
                    addSuccessNotification(this, successMessagesObject)
                    return Promise.resolve(successResponse.data)
                })
                .catch(error => {
                    this.$global.pendingRequests--
                    addErrorNotification(this, error, errorMessage)
                    return Promise.reject(error?.response)
                })
        },

        axiosPut (api, body, errorMessage, successMessagesObject) {
            this.$global.pendingRequests++
            return this.axios.put(`${this.$global.backendPath}/${api}`, body)
                .then(successResponse => {
                    this.$global.pendingRequests--
                    addSuccessNotification(this, successMessagesObject)
                    return Promise.resolve(successResponse.data)
                })
                .catch(error => {
                    this.$global.pendingRequests--
                    addErrorNotification(this, error, errorMessage)
                    return Promise.reject(error?.response)
                })
        },

        axiosDelete (api, body, errorMessage, successMessagesObject) {
            this.$global.pendingRequests++
            return this.axios.delete(`${this.$global.backendPath}/${api}`, { data: body })
                .then(successResponse => {
                    this.$global.pendingRequests--
                    addSuccessNotification(this, successMessagesObject)
                    return Promise.resolve(successResponse.data)
                })
                .catch(error => {
                    this.$global.pendingRequests--
                    addErrorNotification(this, error, errorMessage)
                    return Promise.reject(error?.response)
                })
        }
    },
    computed: {
        async waitForPendingRequests () {
            while (this.$global.pendingRequests > 0) {
                await sleep(50)
            }
            return Promise.resolve()
        }
    }
}

function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function addSuccessNotification (appContext, messagesObject) {
    if (!messagesObject) {
        return
    }
    const userPreferences = appContext.getUserSettingsParameter('userPreferences')
    if (userPreferences.reducedNotifications) {
        if (messagesObject.short) {
            appContext.addCursorNotification({
                type: 'success',
                duration: 3000,
                message: messagesObject.short
            })
        }
    } else {
        if (messagesObject.standard) {
            appContext.addNotification({
                type: 'success',
                duration: 5000,
                message: messagesObject.standard
            })
        }
    }
}

function addErrorNotification (appContext, error, message) {
    const staticErrorCodes = {
        404: 'CF_NotFound',
        403: 'CF_Forbidden',
        408: 'CF_Timeout'
    }
    if (message) {
        let cfError = appContext.$tc('generals.unknownError') // Unknown error as default

        if (staticErrorCodes[error?.response?.status]) { // Predefined error based on response code
            cfError = appContext.$tc(`generals.errorCodes.${staticErrorCodes[error.response.status]}`)
        } else if (error?.response?.data?.messageCode) { // Specific error based on CF message code
            cfError = appContext.$tc(`generals.errorCodes.${error.response.data.messageCode}`)
        } else if (error?.response?.data?.message) { // Internal error
            cfError = error.response.data.message
        }
        appContext.addNotification({
            type: 'error',
            duration: 10000,
            message: `${message} ${cfError}`
        })
    }
}
