<script>
/**
 * RouteLink is an element providing a link to an external website or a part of the application. Can also be used for downloading files.
 * - target (required): The target destination. Either a routing object or an url, based on the property isExternalLink.
 * - isExternalLink (default: false): If true, the link leads to an external website. If false, the link will point to a destination within the application.
 * - tooltip (recommended if iconClass): Text displayed as a tooltip. The tooltip will only appear if you pass an iconClass to the component in order to help the user to understand the meaning of the icon.
 * - displayedText (required if !iconClass): The displayed text. Only visible if no iconClass was passed to the component.
 * - isHighlightedLink (default: false): If true, the displayedText is highlighted by default, in order to draw the user's attention.
 * - iconClass (optional): A font awesome icon class. Overrides displayedText.
 * - hoverIconClass (optional): A font awesome icon class. If provided, this icon will show up on hover.
 * - isDisabled (default: false): If true, there are no pointer events. Hover and click will not work.
 **/
import { regexHelper } from '@/mixins/regexHelper'

export default {
    name: 'RouteLink',
    mixins: [
        regexHelper
    ],
    props: {
        target: [Object, String],
        isExternalLink: {
            default: false,
            type: Boolean
        },
        tooltip: String,
        displayedText: String,
        isHighlightedLink: {
            default: false,
            type: Boolean
        },
        iconClass: String,
        hoverIconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            icon: this.iconClass,
            route: ''
        }
    },
    methods: {
        checkTarget () {
            if (this.isExternalLink &&
                typeof (this.target) === 'string' &&
                !this.isValidUrl(this.target)) {
                this.route = `https://${this.target}`
            } else {
                this.route = this.target
            }
        },

        switchToDefaultIcon () {
            this.icon = this.iconClass
        },

        switchToHoverIcon () {
            if (!this.isDisabled) {
                this.icon = this.hoverIconClass || this.iconClass
            }
        }
    },
    watch: {
        target () {
            this.checkTarget()
        }
    },
    mounted () {
        this.checkTarget()
    }
}
</script>

<template>
    <div class="c_route-link-wrapper"
         v-bind:class="{'m--icon': iconClass}"
         @mouseover="switchToHoverIcon()"
         @mouseleave="switchToDefaultIcon()">
        <a v-if="isExternalLink"
           class="c_route-link"
           v-bind:class="{'m--disabled': isDisabled, 'm--highlighted': isHighlightedLink}"
           v-bind:href="route"
           target="_blank"
           v-on:click="$event.stopPropagation()"
           v-bind:title="tooltip">
            <span v-bind:class="icon">{{!iconClass ? displayedText || route : ''}}</span>
        </a>
        <router-link v-else
                     class="c_route-link"
                     v-bind:class="{'m--disabled': isDisabled, 'm--highlighted': isHighlightedLink}"
                     v-bind:to="route"
                     v-on:click="$event.stopPropagation()"
                     v-bind:title="tooltip">
            <span v-bind:class="icon">{{!iconClass ? displayedText || route : ''}}</span>
        </router-link>
    </div>
</template>

<style lang="less">
.c_route-link-wrapper {
    position: relative;
    display: inline-block;

    &.m--icon {
        width: var(--icon-width);

        .c_route-link {
            font-size: 17px;
            position: relative;
            display: block;
            width: 100%;
            text-align: center;
            padding: 4px 4px 3px 4px;
            border: 0;
            background-color: transparent;

            &.m--disabled {
                pointer-events: none;
                cursor: default;
                color: var(--color-link-disabled);
            }
        }
    }

    .c_route-link {
        color: var(--color-text-mid);

        &:hover, &:active {
            color: var(--color-text-highlighted);
        }

        &.m--highlighted {
            color: var(--color-text-highlighted);

            &:hover, &:active {
                color: var(--color-link-active);
            }
        }

        &.m--disabled {
            pointer-events: none;
            cursor: default;
            color: var(--color-link-disabled);
        }
    }
}

</style>
