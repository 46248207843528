/**
 * A reusable service for notifications. Provides functions for adding, removing or getting the current list of notifications.
 * Adds a notification with an incremented counter to the global list of notifications.
 * Removes a notification from the global list of notifications after it vanished.
 **/
import { userHandler } from '@/mixins/userHandler'
export const notificationHandler = {
    mixins: [
        userHandler
    ],
    methods: {
        /**
         * Despite the name, this will also show a notification
         */
        configureNotification (type, durations, messages) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            const reducedNotifications = userPreferences.reducedNotifications
            const notification = {
                type: type,
                duration: reducedNotifications ? durations.short : durations.standard,
                message: reducedNotifications ? messages.short : messages.standard
            }
            reducedNotifications
                ? this.addCursorNotification(notification)
                : this.addNotification(notification)
        },

        addNotification (notification) {
            this.$global.notifications.standard.index += 1
            notification.id = this.$global.notifications.standard.index
            this.$global.notifications.standard.list.push(notification)
        },

        removeNotification (id) {
            const ids = this.$global.notifications.standard.list.map(notification => notification.id)
            const index = ids.indexOf(id)
            this.$global.notifications.standard.list.splice(index, 1)
        },

        addCursorNotification (notification) {
            this.$global.notifications.reduced.index += 1
            notification.id = this.$global.notifications.reduced.index
            this.$global.notifications.reduced.list.push(notification)
        },

        removeCursorNotification (id) {
            const ids = this.$global.notifications.reduced.list.map(notification => notification.id)
            const index = ids.indexOf(id)
            this.$global.notifications.reduced.list.splice(index, 1)
        }
    },
    computed: {
        getNotifications () {
            return this.$global.notifications.standard.list
        },

        getCursorNotifications () {
            return this.$global.notifications.reduced.list
        }
    }
}
