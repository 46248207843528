/* eslint-disable no-console */
import { register } from 'register-service-worker'

// if you want to enable service worker in development, set this to true and also set devOptions.enabled=true in vite.config.mjs
const environmentSupportsSW = process.env.NODE_ENV === 'production'

if (environmentSupportsSW) {
    const swUrl = process.env.NODE_ENV === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw'
    register(swUrl, {
        ready () {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://vite-pwa-org.netlify.app/'
            )
        },
        registered () {
            console.log('Service worker has been registered.')
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updatefound () {
            console.log('New content is downloading.')
        },
        updated () {
            console.log('New content is available')
            caches.keys().then(cacheNames => {
                if (cacheNames.length > 0) {
                    console.log('Clearing cache in order to provide latest application data.')
                    cacheNames.forEach(cacheName => {
                        return caches.delete(cacheName)
                    })
                    console.log('Cache cleared. Refreshing page...')
                    window.location.reload()
                }
            })
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
