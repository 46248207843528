/**
 * A reusable service for handling environments.
 **/

export const environmentHandler = {
    methods: {
        isLocalEnvironment () {
            return window.location.host.includes('localhost')
        },

        isTestEnvironment () {
            return window.location.host.includes('featuretest') || window.location.host.includes('corflow-dev')
        },

        isStageEnvironment () {
            return window.location.host.includes('stage')
        },

        isTrainingsEnvironment () {
            return window.location.host.includes('training')
        },

        isProductionEnvironment () {
            return !this.isLocalEnvironment() &&
                !this.isTestEnvironment() &&
                !this.isStageEnvironment() &&
                !this.isTrainingsEnvironment()
        }
    }
}
