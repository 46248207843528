/**
 * A reusable service for handling task assignment.
 *
 * @property { Array } boundarySignalEvents
 * @property { Number } triggerCount
 * @property { String } lastAssignee
 **/
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'

// HACK: We have to import $tc directly from 'i18n.global' as `$tc` is not set (anymore)
//       when leaving the context, e.g. clicking outside of a task
//       ERROR message: '$tc is undefined'
//
//       Possibly this is a bug in vue-i18n, but currently this issue is not reported there
//
// see also https://github.com/intlify/vue-i18n/issues/1403#issuecomment-1725176008
import { $tc, $t } from '@/i18n/i18n'

export const taskAssignmentHandler = {
    mixins: [
        axiosService,
        userHandler,
        notificationHandler
    ],
    methods: {
        assignTask (task, openTaskAfterAssignment) {
            const successMessage = task.assignee === this.getUserName()
                ? null
                : {
                    standard: $t('taskAssignmentHandler.assignTaskSuccess.standard', [task.name]),
                    short: $tc('taskAssignmentHandler.assignTaskSuccess.short')
                }

            this.axiosPost(
                `task/${task.id}/claim`,
                null,
                $t('taskAssignmentHandler.assignTaskError', [task.name]),
                successMessage)
                .then(() => {
                    task.assignee = this.getUserName()
                    task.assigneeDisplayText = task.assignee
                    if (openTaskAfterAssignment) {
                        openAssignedTask(this, task)
                    }
                })
                .catch(() => {})
        },

        unAssignTask (task, silentUnAssign, preventSettingLastAssignee, forceUnAssign) {
            if (!task.id) {
                // quick fix: When a Task is unassigned by refreshing the page an unresolved promise is thrown since the task.id is null.
                // Therefore: Do nothing
                return Promise.resolve(true)
            }
            const api = forceUnAssign ? `admin/task/${task.id}/unclaim` : `task/${task.id}/unclaim`
            const body = preventSettingLastAssignee ? { optOut: preventSettingLastAssignee } : {}
            let errorMessage = null
            let successMessage = null
            if (!silentUnAssign) {
                errorMessage = $t('taskAssignmentHandler.unAssignTaskError', [task.name])
                successMessage = {
                    standard: $t('taskAssignmentHandler.unAssignTaskSuccess.standard', [task.name]),
                    short: $tc('taskAssignmentHandler.unAssignTaskSuccess.short')
                }
            }
            return this.axiosPost(api, body, errorMessage, successMessage)
                .then(() => {
                    task.assignee = null
                    task.assigneeDisplayText = this.getAssignmentDisplayText(task)
                    return Promise.resolve(true)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        getTaskHasOrHadAnAssignee (task) {
            return task.assignee || task.lastAssignee
        },

        getTaskIsRelatedToCurrentUser (task) {
            const currentUser = this.getUserName()
            return task.assignee === currentUser || task.lastAssignee === currentUser
        },

        getAssignmentDisplayText (task) {
            return this.getTaskHasOrHadAnAssignee(task)
                ? this.getTaskHasOrHadAnAssignee(task)
                : this.getResponsibleRole(task)
        },

        getResponsibleRole (task) {
            if (!task.sapIds || task.sapIds.length === 0) {
                return $tc('generals.roleLabels.unknown')
            }
            return task.sapIds.map(role => $tc(`generals.roleLabels.${role}`)).join(', ')
        },

        getAssignmentIconType (task) {
            if (!this.getTaskHasOrHadAnAssignee(task)) {
                return 'inactive'
            }
            return this.getTaskIsRelatedToCurrentUser(task)
                ? 'info'
                : 'default'
        },

        getAssignmentIconClass (task) {
            return this.getTaskHasOrHadAnAssignee(task)
                ? 'fas fa-user'
                : 'far fa-user'
        },

        getAssignmentIconTooltip (task) {
            if (!this.getTaskHasOrHadAnAssignee(task)) {
                return $tc('taskAssignmentHandler.taskNotAssigned')
            }
            return this.getTaskIsRelatedToCurrentUser(task)
                ? $tc('taskAssignmentHandler.taskAssignedToMe')
                : $t('taskAssignmentHandler.taskAssignedTo', [task.assignee])
        }
    }
}

function openAssignedTask (appContext, task) {
    const signalEvents = task.boundarySignalEvents.filter(signal => signal.triggerCount === 0)
    const promises = []
    task.waitingForSignalRequests = signalEvents ? signalEvents.length > 0 : false
    appContext.$forceUpdate()
    signalEvents.forEach(signal => promises.push(appContext.axiosPost(
        `task/${task.id}/signal`,
        { signalEventId: signal.id, signalName: signal.signalName }))
    )
    Promise.all(promises)
        .then(() => {
            return appContext.$router.push({ name: 'Task', params: { taskId: task.id } })
        })
}
