<script>
/**
 * The main navigation of the application.
 * Not meant to be a reusable and configurable component.
 **/
import RouteLink from '@/components/RouteLink.vue'
import TextInput from '@/components/TextInput.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import Checkbox from '@/components/Checkbox.vue'
import EmailInput from '@/components/EmailInput.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import Icon from '@/components/Icon.vue'
import DataRow from '@/components/DataRow.vue'
import Button from '@/components/Button.vue'
import HelpText from '@/components/HelpText.vue'
import { axiosService } from '@/mixins/axiosService'
import { urlHelper } from '@/mixins/urlHelper'
import { userHandler } from '@/mixins/userHandler'
import { environmentHandler } from '@/mixins/environmentHandler'

export default {
    name: 'MainNavigation',
    mixins: [
        axiosService,
        userHandler,
        environmentHandler
    ],
    components: {
        RouteLink,
        TextInput,
        InteractionLink,
        SmartFormSubheading,
        Checkbox,
        EmailInput,
        ModalContainer,
        Icon,
        DataRow,
        Button,
        HelpText
    },
    data () {
        return {
            languages: {
                german: 'de',
                english: 'en'
            },
            username: this.getUserName(),
            productPreviewExpanded: false,
            productPreview: null,
            openProductWhenCreated: false,
            showProfileBadge: true,
            isLoading: false,
            isEditingContactMail: false,
            filterSettings: {
                // enum constant > related "table-id" value
                PRODUCT: 'productList',
                TASK: 'taskList',
                MIGRATION: 'processMigration',
                PRODUCT_COMPLETION: 'adminProducts'
            },
            notificationSettings: {
                REDUCED: 'reducedNotifications',
                PRODUCT: 'notificationNewProduct',
                TASK: 'notificationNewTask',
                TASKTERMINATION: 'notificationTaskTermination'
            },
            measurementUnits: [
                'retailPrice',
                'breite',
                'hoehe',
                'rueckenStaerke',
                'gesamtUmfang',
                'totalPageCount'
            ],
            fieldsWithBooleanValue: [
                'isEinreichungNecessary',
                'testEditionPlanned'
            ],
            fieldsWithLinkValue: [
                'linkToStyleGuide'
            ],
            fieldsWithCustomValue: {
                productState: {
                    key: 'productStateValues',
                    values: [
                        'CREATED', 'IN_DEVELOPMENT', 'FINALIZED', 'IMPRIMATUR_FINISHED', 'ALL_FINISHED'
                    ]
                },
                materialType: {
                    key: 'materialTypeValues',
                    values: [
                        'ZSTP', 'ZSTN', 'ZUFE', 'ZUFN', 'ZZSA', 'ZMPR', 'ZMPF', 'ZWMI', 'ZWMN', 'ZSON'
                    ]
                },
                poolProduction: {
                    key: 'poolProductionValues',
                    values: [
                        'no', '50pct', '80pct'
                    ]
                }
            },
            helpTextWidth: 305,
            clickerCount: 0,
            clickerMax: 5,
            showLogo: false
        }
    },
    methods: {
        isSelected (navigationItem) {
            const path = this.$route.fullPath
            const target = navigationItem.path || navigationItem.target
            return path.includes(target)
        },

        openProductList () {
            if (this.$router.currentRoute.value.name !== 'ProductList') {
                this.$router.push({ name: 'ProductList' })
            }
        },

        clickHandler (event, navigationItem) {
            event.stopPropagation()
            if (navigationItem.action) {
                navigationItem.action()
            }
        },

        toggleMultipleLinkContainer (event, navigationItem, isOpen) {
            event.stopPropagation()
            navigationItem.isOpen = isOpen
            this.$forceUpdate()
        },

        isRouteLink (navItem) {
            return !navItem.items || (navItem.items.length === 0 && !navItem.inputConfig)
        },

        isProductExisting (productNumber, notifyIfProductExists) {
            return this.axiosGet(
                `product/search?query=${productNumber}`,
                this.$t('mainNavigation.searchError', [productNumber]))
                .then(foundProduct => {
                    const productExists = !!foundProduct.id
                    if (productExists && notifyIfProductExists) {
                        const userPreferences = this.getUserSettingsParameter('userPreferences')
                        if (userPreferences.reducedNotifications) {
                            this.addCursorNotification({
                                type: 'info',
                                duration: 3000,
                                message: this.$t('mainNavigation.productExists.short', [productNumber])
                            })
                        } else {
                            this.addNotification({
                                type: 'info',
                                duration: 5000,
                                message: this.$t('mainNavigation.productExists.standard', [productNumber])
                            })
                        }
                    }
                    return productExists
                })
                .catch(() => {
                    return null
                })
        },

        async searchSapProduct (inputSubmitEventData) {
            this.isLoading = true
            const searchText = inputSubmitEventData.value
            const productExists = await this.isProductExisting(searchText, true)
            if (productExists === false) {
                this.axiosGet(
                    `product/sap/${searchText}`,
                    this.$t('mainNavigation.searchError', [searchText]))
                    .then(sapProduct => {
                        this.productPreview = {
                            fields: {}
                        }
                        Object.keys(sapProduct.baseInformation.fields).forEach(key => {
                            this.productPreview.fields[key] = sapProduct.baseInformation.fields[key]
                        })
                        this.productPreview.fields.editors = sapProduct.internalContributors.fields.editors
                        this.productPreview.fields.leadEditors = sapProduct.internalContributors.fields.leadEditors
                        let conflicts = sapProduct.baseInformation.conflicts
                        if (sapProduct.equipmentData) {
                            conflicts = conflicts.concat(sapProduct.equipmentData.conflicts)
                            this.productPreview.fields.druckAuflage = sapProduct.equipmentData.fields?.druckAuflage
                            this.productPreview.fields.druckAuflagenStatus = sapProduct.equipmentData.fields?.druckAuflagenStatus
                        }
                        this.productPreview.conflicts = conflicts
                        this.setProductPreviewExpanded(true)
                        this.isLoading = false
                    })
                    .catch(() => {
                        this.isLoading = false
                    })
            } else {
                this.isLoading = false
            }
        },

        setProductPreviewExpanded (expand) {
            this.productPreviewExpanded = expand
            if (expand === false) {
                setTimeout(() => {
                    this.productPreview = null
                    this.openProductWhenCreated = false
                }, 200)
            }
        },

        getProductPreviewMetadataConflict (previewMetadata) {
            return this.productPreviewHasConflicts
                ? this.productPreview.conflicts.find(conflict => conflict.field === previewMetadata.field)
                : null
        },

        productPreviewMetadataHasConflict (previewMetadata) {
            return !!this.getProductPreviewMetadataConflict(previewMetadata)
        },

        getProductPreviewMetadataTooltip (previewMetadata) {
            const conflict = this.getProductPreviewMetadataConflict(previewMetadata)
            return conflict
                ? this.$tc(`generals.errorCodes.${conflict.errorCode}`)
                : null
        },

        createProduct () {
            this.isLoading = true
            this.axiosPost(
                `product/sap/${this.productPreview.fields.productNumber}`,
                null,
                this.$tc('mainNavigation.productPreview.createProductError'),
                {
                    standard: this.$tc('mainNavigation.productPreview.createProductSuccess.standard'),
                    short: this.$tc('mainNavigation.productPreview.createProductSuccess.short')
                })
                .then(createdProductId => {
                    if (this.openProductWhenCreated) {
                        this.$router.push({
                            name: 'Product',
                            params: {
                                productId: createdProductId
                            }
                        })
                    } else if (this.$router.currentRoute.value.name === 'ProductList') {
                        window.dispatchEvent(new CustomEvent('appendProduct', { detail: createdProductId }))
                    }
                    this.setProductPreviewExpanded(false)
                    this.isLoading = false
                })
                .catch(() => {
                    this.isLoading = false
                })
        },

        switchLanguage () {
            this.$global.localization.locale = this.$global.localization.locale === this.languages.german
                ? this.languages.english
                : this.languages.german
        },

        calculateUserInitials () {
            const usernameParts = this.username.split('.')
            let i = 0
            const len = 2
            let initials = ''

            for (i; i < len; i += 1) {
                if (usernameParts[i]) {
                    initials += usernameParts[i].charAt(0)
                }
            }
            return initials
        },

        setProfileBadgeVisibility (visible) {
            this.showProfileBadge = visible
        },

        openReleaseNotes () {
            window.open(this.getReleaseNotesUrl, '_blank')
        },

        setIsEditingContactMail (editing) {
            this.isEditingContactMail = editing
        },

        resetUserFilter (filterProperty, value) {
            this.updateUserSettingsParameter(
                filterProperty,
                value,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.resetUserFilterSuccess.standard'),
                    short: this.$tc('mainNavigation.resetUserFilterSuccess.short')
                })
        },

        getUserPreference (field) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            return userPreferences[field]
        },

        setUserPreference (field, value) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            userPreferences[field] = value || !userPreferences[field]
            this.updateUserSettingsParameter(
                'userPreferences',
                userPreferences,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.saveUserPreferencesSuccess.standard'),
                    short: this.$tc('mainNavigation.saveUserPreferencesSuccess.short')
                })
        },

        updateContactMail (inputSubmitEventData) {
            this.updateContactMailInKeycloak(
                inputSubmitEventData.value,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.saveUserPreferencesSuccess.standard'),
                    short: this.$tc('mainNavigation.saveUserPreferencesSuccess.short')
                })
            this.setIsEditingContactMail(false)
        }
    },
    computed: {
        isAdminUser () {
            return this.getUserRoles().includes('admin')
        },

        getAppLogoUrlAndTabTitle () {
            const testInstancesCount = 8
            const host = window.location.host
            const logoAndTitleConfig = {
                'corflow-dev.cornelsen.io': {
                    title: 'CorFlow - Dev',
                    logo: 'img/cornelsen-development.png'
                },
                'corflow-training.cornelsen.io': {
                    title: 'CorFlow - Training',
                    logo: 'img/cornelsen-training.png'
                },
                'corflow-stage.cornelsen.io': {
                    title: 'CorFlow - Stage',
                    logo: 'img/cornelsen-stage.png'
                },
                'corflow-pm.cornelsen.io': {
                    title: 'CorFlow - Process Migration',
                    logo: 'img/cornelsen-process-migration.png'
                },
                'corflow.cornelsen.io': {
                    title: 'CorFlow',
                    logo: 'img/cornelsen-production.png'
                }
            }
            if (!logoAndTitleConfig[host]) {
                for (let i = 1; i <= testInstancesCount; i++) {
                    logoAndTitleConfig[`corflow-featuretest-${i}.cornelsen.io`] = {
                        title: `CorFlow - Test ${i}`,
                        logo: 'img/cornelsen-test.png'
                    }
                }
            }
            document.title = logoAndTitleConfig[host] ? logoAndTitleConfig[host].title : 'CorFlow - Local'
            if (this.clickerCount >= this.clickerMax) {
                return 'img/corflow-logo.jpg'
            }
            return logoAndTitleConfig[host] ? logoAndTitleConfig[host].logo : 'img/cornelsen-local.png'
        },

        getNavigationItems () {
            const isAdmin = this.isAdminUser
            return [{
                id: 'nav.productList',
                label: this.$tc('mainNavigation.productList'),
                target: '/product-list',
                action: this.openProductList,
                isOpen: false,
                itemsTitle: this.$tc('mainNavigation.lastProducts'),
                items: this.getUserSettingsParameter('lastProducts'),
                inputConfig: {
                    placeholder: this.$tc('mainNavigation.productSearchPlaceholder'),
                    icon: 'fas fa-plus',
                    action: this.searchSapProduct
                }
            }, {
                id: 'nav.taskList',
                label: this.$tc('mainNavigation.taskList'),
                path: '/task-list',
                target: '/task-list/visible',
                isOpen: false,
                items: []
            }, {
                id: 'nav.admin',
                label: this.$tc('mainNavigation.administration'),
                target: '/administration',
                isOpen: false,
                hidden: !isAdmin,
                itemsTitle: this.$tc('mainNavigation.adminArea'),
                items: [{
                    label: this.$tc('mainNavigation.adminTasks'),
                    target: '/administration/task-overview'
                }, {
                    label: this.$tc('mainNavigation.adminControlling'),
                    target: '/administration/controlling'
                }, {
                    label: this.$tc('mainNavigation.playground'),
                    target: '/administration/playground',
                    hidden: !this.isLocalEnvironment() && !this.isTestEnvironment()
                }, {
                    label: this.$tc('mainNavigation.gallery'),
                    target: '/administration/gallery',
                    hidden: !this.isLocalEnvironment() && !this.isTestEnvironment()
                }]
            }]
        },

        getProductPreviewMetadata () {
            function getDisplayValue (appContext, value) {
                if (Array.isArray(value) && value !== null) {
                    return value.join(', ')
                }
                if (typeof value === 'object' && value !== null) {
                    return value.name
                }
                if (isDateRegex(value)) {
                    return appContext.$d(new Date(value), 'short')
                }
                return value
            }
            function isDateRegex (value) {
                if (new Date(value).toString() === 'Invalid Date') {
                    return false
                }
                const regex = /^\d{4}-\d{2}-\d{2}$/
                return value.match(regex)
            }
            const metadataItems = []
            for (const [fieldName, value] of Object.entries(this.productPreview.fields)) {
                let isClickableValue = false
                let fieldValue = getDisplayValue(this, value)
                if (fieldValue && this.measurementUnits.includes(fieldName) && fieldValue !== '–') {
                    fieldValue += this.$tc(`product.details.fields.measurementUnits.${fieldName}`)
                }
                if (fieldValue && this.fieldsWithCustomValue[fieldName]) {
                    const fieldConfig = this.fieldsWithCustomValue[fieldName]
                    const messagePropertyKey = fieldConfig.values.includes(fieldValue)
                        ? fieldValue
                        : 'UNKNOWN'
                    fieldValue = this.$tc(`generals.${fieldConfig.key}.${messagePropertyKey}`)
                }
                // temporary fix for edition
                if (fieldName === 'druckAuflage' && this.getProductPreviewMetadataConflict({ field: fieldName })) {
                    fieldValue = '>1'
                }
                if (this.fieldsWithLinkValue.includes(fieldName) && urlHelper.methods.isUrlValue(fieldValue)) {
                    isClickableValue = true
                }
                if (this.fieldsWithBooleanValue.includes(fieldName)) {
                    if (fieldValue === 'true' || fieldValue === 'false') {
                        fieldValue = this.$tc(`generals.booleanValues.${fieldValue}`)
                    }
                }
                metadataItems.push({
                    field: fieldName,
                    label: this.$tc(`product.details.fields.${fieldName}`),
                    value: fieldValue,
                    isClickableValue: isClickableValue
                })
            }
            return metadataItems
        },

        productPreviewHasConflicts () {
            return this.productPreview.conflicts && this.productPreview.conflicts.length > 0
        },

        getVersion () {
            return this.$global.currentVersion.toString()
        },

        getManualUrl () {
            const language = this.$global.localization.locale
            return this.$global.manualUrl[language]
        },

        getReleaseNotesUrl () {
            const language = this.$global.localization.locale
            return this.$global.releaseNotesUrl[language]
        },

        getFrontendBranch () {
            const index = this.$global.currentBranches.frontend.lastIndexOf('/') + 1
            return this.$global.currentBranches.frontend.substring(index)
        },

        getBackendBranch () {
            const index = this.$global.currentBranches.backend.lastIndexOf('/') + 1
            return this.$global.currentBranches.backend.substring(index)
        },

        getEmail () {
            return this.getUserEmail()
        }
    }
}
</script>

<template>
    <header class="main-navigation-wrapper">
        <img alt="Cornelsen - CorFlow"
             class="main-navigation-logo"
             v-bind:class="{'m--official': clickerCount >= clickerMax}"
             v-on:click="clickerCount < clickerMax
                ? clickerCount++
                : showLogo=!showLogo"
             v-bind:src="getAppLogoUrlAndTabTitle" />
        <transition name="fade">
            <img v-if="showLogo"
                 alt="CorFlow"
                 class="main-navigation-logo-large"
                 v-on:click="showLogo=false"
                 v-bind:src="'img/logo.png'" />
        </transition>
        <nav class="main-navigation">
            <div v-for="navItem in getNavigationItems"
                 v-bind:key="navItem.label">
                <div v-if="!navItem.hidden">
                    <router-link v-if="isRouteLink(navItem)"
                                 class="main-navigation-link"
                                 v-bind:class="{'m--selected': isSelected(navItem)}"
                                 v-bind:to="navItem.target">
                        <span>{{navItem.label}}</span>
                    </router-link>
                    <a v-else
                       v-on:mouseenter="toggleMultipleLinkContainer($event, navItem, true)"
                       v-on:mouseleave="toggleMultipleLinkContainer($event, navItem, false)"
                       v-on:click="clickHandler($event, navItem)"
                       tabindex="-1"
                       class="main-navigation-link m--multiple"
                       v-bind:class="{'m--selected': isSelected(navItem)}">
                        <span>{{navItem.label}}</span>
                        <transition name="fade">
                            <div v-show="navItem.isOpen"
                                 class="main-navigation-link-multiple-container">
                                <div v-if="navItem.inputConfig  && getUserHasRightToImportProduct()">
                                    <TextInput class="main-navigation-multi-input"
                                               v-bind:placeholder="navItem.inputConfig.placeholder"
                                               v-bind:submit-button=true
                                               v-bind:reset-on-submit="true"
                                               v-bind:icon-class="isLoading ? 'fas fa-circle-notch fa-spin' : navItem.inputConfig.icon"
                                               @input-submit="navItem.inputConfig.action">
                                    </TextInput>
                                </div>
                                <div v-if="navItem.items.length > 0"
                                     class="main-navigation-multi-title">
                                    <span>{{navItem.itemsTitle}}</span>
                                </div>
                                <template v-for="item in navItem.items">
                                    <router-link v-if="!item.hidden"
                                                 v-bind:key="item.target"
                                                 v-bind:to="item.target"
                                                 class="main-navigation-multi-link"
                                                 v-bind:class="{'m--selected': isSelected(item)}"
                                                 v-on:click="$event.stopPropagation()">
                                        <span v-dompurify-html="item.label"></span>
                                    </router-link>
                                </template>
                            </div>
                        </transition>
                    </a>
                </div>
            </div>
        </nav>
        <div class="main-navigation-addons">
            <RouteLink class="main-navigation-help"
                       v-bind:target="getManualUrl"
                       v-bind:is-external-link=true
                       v-bind:displayed-text="$tc('mainNavigation.help')">
            </RouteLink>
            <span class="main-navigation-language-switch"
                  v-bind:title="$tc('mainNavigation.languageTooltip')"
                  v-on:click="switchLanguage()">{{$tc('mainNavigation.language')}}</span>
        </div>
        <div class="main-navigation-profile generals-animate"
             v-bind:class="{'m--visible': showProfileBadge}"
             v-on:click="setProfileBadgeVisibility(false)">
            <span>{{calculateUserInitials()}}</span>
        </div>
        <div class="main-navigation-profile-details generals-animate"
             v-bind:class="{'m--visible': !showProfileBadge}"
             v-on:click="setIsEditingContactMail(false)">
            <div class="main-navigation-profile-details-heading">
                <span>{{$t('mainNavigation.salutation', [getUserFirstName()])}}</span>
                <span class="main-navigation-profile-details-close fas fa-times"
                      v-on:click="setProfileBadgeVisibility(true)">
                </span>
            </div>
            <div class="main-navigation-profile-details-body">
                <div class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.currentReleaseLink')}:`"
                                     v-bind:value="getVersion"
                                     v-bind:is-highlighted="true"
                                     v-bind:is-underlined="true"
                                     v-bind:tooltip="getReleaseNotesUrl"
                                     @input-click="openReleaseNotes()">
                    </InteractionLink>
                </div>
                <div class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.currentUser')}:`"
                                     v-bind:value="username"
                                     v-bind:is-disabled="true">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser">
                    <SmartFormSubheading class="main-navigation-profile-details-subheading"
                                         v-bind:label="$tc('mainNavigation.currentBranches')">
                    </SmartFormSubheading>
                    <div class="main-navigation-profile-details-container">
                        <DataRow v-bind:label="`${$tc('mainNavigation.currentFrontendBranch')}:`"
                                 v-bind:value="getFrontendBranch"
                                 v-bind:label-width-percentage="52"
                                 v-bind:no-line-break="true">
                        </DataRow>
                    </div>
                    <div class="main-navigation-profile-details-container">
                        <DataRow v-bind:label="`${$tc('mainNavigation.currentBackendBranch')}:`"
                                 v-bind:value="getBackendBranch"
                                 v-bind:label-width-percentage="52"
                                 v-bind:no-line-break="true">
                        </DataRow>
                    </div>
                </div>
                <SmartFormSubheading class="main-navigation-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.filterSettings')">
                </SmartFormSubheading>
                <div class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.productListFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(
                                         filterSettings.PRODUCT,
                                         { userIsParticipantFilterText: [$tc('productList.table.productForMe').toLowerCase()]}
                                     )">
                    </InteractionLink>
                </div>
                <div class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.taskListFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(
                                       filterSettings.TASK,
                                       { isTaskExecutableFilterText: [$tc('taskList.table.tasksForMe').toLowerCase()]}
                                   )">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser"
                     class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.processMigrationFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(filterSettings.MIGRATION, {})">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser"
                     class="main-navigation-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.productCompletionFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(filterSettings.PRODUCT_COMPLETION, {})">
                    </InteractionLink>
                </div>
                <SmartFormSubheading class="main-navigation-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.systemSettings')">
                </SmartFormSubheading>
                <div class="main-navigation-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.REDUCED) || false"
                              v-bind:label="`${$tc('mainNavigation.setNotificationType')}:`"
                              @input-change="setUserPreference(notificationSettings.REDUCED)">
                    </Checkbox>
                </div>
                <div class="main-navigation-profile-details-container">
                    <InteractionLink v-if="!isEditingContactMail"
                                     v-bind:label="`${$tc('mainNavigation.setContactMail')}:`"
                                     v-bind:value="getEmail|| username"
                                     v-bind:is-underlined="true"
                                     icon-class="fas fa-edit"
                                     @input-click="setIsEditingContactMail(true)">
                    </InteractionLink>
                    <EmailInput v-else
                                v-bind:default-value="getEmail"
                                v-bind:label="`${$tc('mainNavigation.setContactMail')}:`"
                                icon-class="fas fa-check"
                                v-bind:show-unsaved-changes="true"
                                v-bind:submit-button="true"
                                @input-blurred="updateContactMail($event)"
                                @input-submit="updateContactMail($event)">
                    </EmailInput>
                    <HelpText class="main-navigation-profile-details-help-container m--mail"
                              v-bind:text="$tc('mainNavigation.setContactMailHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <div class="main-navigation-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.TASKTERMINATION) || false"
                              v-bind:label="`${$tc('mainNavigation.setCancelTaskTermination')}:`"
                              @input-change="setUserPreference(notificationSettings.TASKTERMINATION)">
                    </Checkbox>
                    <HelpText class="main-navigation-profile-details-help-container"
                              v-bind:text="$tc('mainNavigation.setCancelTaskTerminationHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <SmartFormSubheading class="main-navigation-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.notificationSettings')">
                </SmartFormSubheading>
                <div class="main-navigation-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.PRODUCT) || false"
                              v-bind:label="`${$tc('mainNavigation.setNotificationProduct')}:`"
                              @input-change="setUserPreference(notificationSettings.PRODUCT)">
                    </Checkbox>
                    <HelpText class="main-navigation-profile-details-help-container"
                              v-bind:text="$tc('mainNavigation.setNotificationProductHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <div class="main-navigation-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.TASK) || false"
                              v-bind:label="`${$tc('mainNavigation.setNotificationTask')}:`"
                              @input-change="setUserPreference(notificationSettings.TASK)">
                    </Checkbox>
                    <HelpText class="main-navigation-profile-details-help-container"
                              v-bind:text="$tc('mainNavigation.setNotificationTaskHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
            </div>
        </div>
        <ModalContainer v-bind:is-expanded="productPreviewExpanded"
                        v-bind:disable-screen="true"
                        v-bind:modal-title="$tc('mainNavigation.productPreview.title')"
                        v-bind:modal-width-percent="40"
                        class="main-navigation-modal-container"
                        @modal-close="setProductPreviewExpanded(false)">
            <template v-if="productPreview"
                      v-slot>
                <div class="main-navigation-modal-header">
                    <template v-if="productPreviewHasConflicts">
                        <Icon class="main-navigation-modal-error-icon"
                              icon-class="fas fa-exclamation-circle"
                              icon-type="error"
                              v-bind:icon-large="true">
                        </Icon>
                        <div>{{$tc('mainNavigation.productPreview.conflictMessage')}}</div>
                    </template>
                    <template v-else>
                        <Checkbox class="main-navigation-modal-checkbox"
                                  v-bind:label="$tc('mainNavigation.productPreview.openProductWhenCreated')"
                                  @input-change="openProductWhenCreated=$event.value">
                        </Checkbox>
                        <Button class="main-navigation-modal-create-button"
                                v-bind:is-disabled="isLoading"
                                button-type="submit"
                                @button-submit="createProduct()">
                            <template v-slot>
                            <span>
                                <span v-if="isLoading"
                                      class="fas fa-circle-notch fa-spin"></span>
                                {{$tc('mainNavigation.productPreview.createProduct')}}
                            </span>
                            </template>
                        </Button>
                    </template>
                </div>
                <div class="main-navigation-modal-body">
                    <DataRow v-for="item in getProductPreviewMetadata"
                             v-bind:class="{'main-navigation-modal-error': productPreviewMetadataHasConflict(item)}"
                             v-bind:key="item.label"
                             v-bind:label="item.label"
                             v-bind:message-type="productPreviewMetadataHasConflict(item) ? 'error' : 'default'"
                             v-bind:tooltip="getProductPreviewMetadataTooltip(item)"
                             v-bind:value="item.value"
                             v-bind:label-width-percentage=40
                             v-bind:is-separated-by-bottom-border=false>
                    </DataRow>
                </div>
            </template>
        </ModalContainer>
    </header>
</template>

<style lang="less">
.main-navigation-wrapper {
    height: var(--main-navigation-height);
    padding: 5px var(--container-spacing);
    border-bottom: 2px solid var(--color-border-light);
    display: flex;
    font-size: 16px;

    .main-navigation-logo {
        width: auto;
        height: 45px;
        margin: 4.5px var(--container-spacing) 0 0;

        &.m--official {
            height: 100%;
            margin: 0 140px 0 0;
            cursor: pointer;
        }
    }

    .main-navigation-logo-large {
        width: 300px;
        height: 300px;
        position: absolute;
        top: calc(var(--main-navigation-height) + 10px);
        left: 10px;
        border: 2px solid var(--color-border-light);
        border-radius: 10px;
        background-color: var(--color-background-default);
        z-index: var(--z-index-main-navigation);
    }

    .main-navigation {
        width: 65%;
        display: flex;
        height: 52px;
        padding-top: 16px;
        margin: 0 auto;

        .main-navigation-link {
            height: 45px;
            width: var(--main-navigation-link-width);
            display: block;
            text-align: center;
            color: var(--color-text-mid);
            cursor: pointer;
            user-select: none;
            outline: none;
            padding-top: 1px;

            &:hover {
                color: var(--color-link);
            }

            &.m--multiple:after {
                position: absolute;
                margin: 2px 0 0 10px;
                content: "\f0d7";
                font-family: "Font Awesome 5 Free", serif;
                font-weight: 900;
            }

            &.m--selected {
                color: var(--color-link);
                border-bottom: 2px solid var(--color-border-active);
            }
        }

        .main-navigation-link-multiple-container {
            position: absolute;
            width: var(--main-navigation-link-width);
            z-index: var(--z-index-main-navigation);
            margin-top: 22px;
            border-top: 2px solid var(--color-border-light);
            background-color: var(--color-background-default);
            box-shadow: 0 6px 10px 0 var(--color-border-dark);
            cursor: default;

            .main-navigation-multi-input {
                width: 100%;
                padding: 8px;
                background-color: var(--color-background-light);
                border-bottom: 1px solid var(--color-border-light);

                .c_text-input-container {
                    width: 100%;
                    height: 30px;

                    .c_text-input {
                        border-radius: 20px;
                        padding-top: 0;
                        padding-left: 12px;
                        border: 1px solid var(--color-border-light);
                        &::placeholder {
                            color: var(--color-text-light);
                        }

                        +button {
                            top: 5px;
                            right: 5px;
                        }
                    }
                }
            }

            .main-navigation-multi-title {
                width: calc(var(--main-navigation-link-width));
                height: 30px;
                display: block;
                text-align: center;
                background-color: var(--color-background-light);
                color: var(--color-text-mid);
                padding-top: 5px;
                user-select: none;
                font-family: "Source Sans Pro Bold", sans-serif;
                font-size: 14px;
            }

            .main-navigation-multi-link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(var(--main-navigation-link-width));
                min-height: var(--main-navigation-line-height);
                text-align: center;
                padding: 10px;
                color: var(--color-text-mid);
                cursor: pointer;
                user-select: none;

                &.m--selected {
                    background-color: var(--color-background-highlighted);
                    color: var(--color-text-bright);
                    pointer-events: none;
                }

                &:hover {
                    color: var(--color-text-highlighted);
                }
            }
        }
    }

    .main-navigation-addons {
        padding-top: 1px;
        display: flex;
        margin: 16px 90px 16px 0;

        :not(:last-child) {
            margin-right: 40px;
        }

        .main-navigation-help {
            width: 125px;
        }

        .main-navigation-language-switch {
            cursor: pointer;
            &:hover {
                color: var(--color-text-highlighted);
            }
        }
    }

    .main-navigation-profile {
        position: absolute;
        right: -500px;
        height: var(--main-navigation-line-height);
        width: var(--main-navigation-line-height);
        border-radius: 50%;
        border: 2px solid var(--color-border-dark);
        color: var(--color-text-mid);
        font-size: 24px;
        text-transform: uppercase;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        margin-top: 2px;

        &:hover {
            color: var(--color-link);
            border-color: var(--color-border-active);
        }

        &.m--visible {
            right: 16px;
        }
    }

    .main-navigation-profile-details {
        position: absolute;
        top: var(--container-spacing);
        right: -1000px;
        width: auto;
        height: auto;
        background-color: var(--color-background-default);
        border: 1px solid var(--color-border-light);
        z-index: var(--z-index-main-navigation);
        box-shadow: 0 5px 10px 0 var(--color-border-dark);

        &.m--visible {
            right: var(--container-spacing);
        }

        .main-navigation-profile-details-heading {
            position: relative;
            height: var(--main-navigation-line-height);
            padding: 14px 35px 14px 14px;
            font-family: "Source Sans Pro Bold", sans-serif;
            background-color: var(--color-background-light);

            .main-navigation-profile-details-close {
                position: absolute;
                cursor: pointer;
                font-size: 14px;
                right: 16px;
                top: 18px;

                &:hover {
                    color: var(--color-icon-active);
                }
            }
        }

        .main-navigation-profile-details-body {
            position: relative;
            max-height: 80vh;
            width: 645px;
            cursor: default;
            padding: calc(var(--container-spacing) / 2) 0 var(--container-spacing) 0;
            overflow-x: hidden;
            overflow-y: auto;

            .main-navigation-profile-details-subheading {
                margin: 30px 14px 10px 14px;
            }

            .main-navigation-profile-details-container {
                position: relative;
                width: 100%;
                height: var(--input-height);
                padding: 0 14px;

                .generals-input-label {
                    width: 300px;
                }

                .c_data-row-wrapper {
                    border: 0;

                    .c_data-row-label,
                    .c_data-row-value {
                        padding: 13px 0;
                    }
                }

                .main-navigation-profile-details-help-container {
                    position: absolute;
                    right: 35px;
                    bottom: 28px;

                    &.m--mail {
                        bottom: 26px;
                    }
                }
            }
        }
    }

    .main-navigation-modal-container {

        .c_modal-container.m--expanded {

            .c_modal-container-body {
                padding: 0;
            }
        }

        .main-navigation-modal-header {
            width: 100%;
            height: var(--primary-headline-height);
            padding: 14px var(--container-spacing);
            border-bottom: 1px solid var(--color-border-light);

            .main-navigation-modal-error-icon {
                float: left;
                margin: 6px 12px 0 -10px
            }

            .main-navigation-modal-create-button {
                float: right;
            }

            .main-navigation-modal-checkbox {
                position: absolute;
                margin-top: -1px;

                .c_checkbox-label,
                .c_checkbox-container {
                    width: auto;
                }
            }
        }

        .main-navigation-modal-body {
            width: 100%;
            height: calc(100% - var(--primary-headline-height));
            padding: 4px 0;
            overflow-y: auto;

            .main-navigation-modal-error {
                background-color: rgba(162, 39, 28, 0.1);

                .c_data-row-value {
                    color: var(--color-error);
                }
            }
        }
    }
}
</style>
