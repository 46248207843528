<script>
/**
 * The main app.
 *
 * @property { String } gitBranch
 * @property { Object } userAgentData
 **/

// @ is an alias to /src
import MainNavigation from '@/components/MainNavigation.vue'
import Notification from '@/components/Notification.vue'
import CursorNotification from '@/components/CursorNotification.vue'
import Button from '@/components/Button.vue'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { userHandler } from '@/mixins/userHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'

export default {
    name: 'App',
    mixins: [
        axiosService,
        notificationHandler,
        userHandler,
        taskAssignmentHandler
    ],
    components: {
        MainNavigation,
        Notification,
        CursorNotification,
        Button
    },
    data () {
        return {
            appInitialized: false,
            delayedTaskConfig: null,
            maintenanceMessageDismissed: false
        }
    },
    methods: {
        async initAppInformation () {
            this.initListOfUsers()
            const versionInfo = await this.axiosGet('application/info')
            this.$global.currentBranches.backend = versionInfo.gitBranch
            this.initUserSettings()
                .then(() => {
                    this.appInitialized = true
                    const lastLocation = this.getUserSettingsParameter('lastLocation')
                    if (lastLocation && this.$router.currentRoute.value.path !== lastLocation.path) {
                        this.$router.replace(lastLocation)
                    }
                })
                .catch(() => {})
        },

        setMousePosition (event) {
            this.$global.mousePosition.x = event.clientX
            this.$global.mousePosition.y = event.clientY
        },

        saveLocation () {
            const currentRoute = this.$router.currentRoute.value
            if (currentRoute.name !== 'Task') {
                const lastLocation = {
                    name: currentRoute.name,
                    path: currentRoute.path,
                    params: currentRoute.params
                }
                this.updateUserSettingsParameter('lastLocation', lastLocation)
            }
        },

        dismissMaintenanceMessage () {
            this.maintenanceMessageDismissed = true
        },

        delayTaskTermination (event) {
            this.delayedTaskConfig = {
                timeout: event.detail.timeout,
                task: event.detail.task,
                secondsLeft: 10,
                interval: setInterval(() => {
                    this.delayedTaskConfig.secondsLeft--
                    if (this.delayedTaskConfig.secondsLeft === -1) {
                        clearInterval(this.delayedTaskConfig.interval)
                    }
                }, 900)
            }
        },

        stopTaskTermination () {
            const taskId = this.delayedTaskConfig.task.id
            this.resetTaskTermination()
            this.$router.push({ name: 'Task', params: { taskId: taskId } })
        },

        resetTaskTermination () {
            clearTimeout(this.delayedTaskConfig.timeout)
            clearInterval(this.delayedTaskConfig.interval)
            this.delayedTaskConfig = null
        },

        completeDelayedTask () {
            if (this.delayedTaskConfig) {
                this.axiosPost(`task/${this.delayedTaskConfig.task.id}/complete`)
            }
        }
    },
    computed: {
        getListOfNotifications () {
            return this.getNotifications
        },

        getListOfCursorNotifications () {
            return this.getCursorNotifications
        },

        isMacOs () {
            let platFormInfo = 'unknown'
            if (navigator && navigator.userAgent) {
                platFormInfo = navigator.userAgent
            }
            return platFormInfo.toLowerCase().includes('mac')
        },

        showMaintenanceMessage () {
            return this.$global.showMaintenanceMessage && !this.maintenanceMessageDismissed
        }
    },
    watch: {
        '$route' () {
            this.saveLocation()
        }
    },
    beforeMount () {
        this.initAppInformation()
        window.addEventListener('mousemove', this.setMousePosition)
        window.addEventListener('delayTaskTermination', this.delayTaskTermination)
        window.addEventListener('delayTaskTerminationExpired', this.resetTaskTermination)
        window.addEventListener('beforeunload', this.completeDelayedTask)
    },
    beforeUnmount () {
        window.removeEventListener('mousemove', this.setMousePosition)
        window.removeEventListener('delayTaskTermination', this.delayTaskTermination)
        window.removeEventListener('delayTaskTerminationExpired', this.resetTaskTermination)
        window.removeEventListener('beforeunload', this.completeDelayedTask)
    }
}
</script>

<template>
    <div v-if="appInitialized"
         id="app"
         class="app"
         v-bind:class="{'m--styled-scrollbar': !isMacOs}">
        <Notification v-for="notification in getListOfNotifications"
                      v-bind:key="`notification_${notification.id}`"
                      v-bind:id="`notification_${notification.id}`"
                      v-bind:type="notification.type"
                      v-bind:duration="notification.duration"
                      v-bind:message="notification.message"
                      placement="top"
                      @notification-vanished="removeNotification">
        </Notification>
        <CursorNotification v-for="notification in getListOfCursorNotifications"
                            v-bind:key="`cursor-notification_${notification.id}`"
                            v-bind:id="`cursor-notification_${notification.id}`"
                            v-bind:type="notification.type"
                            v-bind:duration="notification.duration"
                            v-bind:message="notification.message"
                            @notification-vanished="removeCursorNotification">
        </CursorNotification>
        <transition name="fade">
            <div v-if="showMaintenanceMessage"
                 class="app-maintenance-message-wrapper">
                <div class="app-maintenance-message-container">
                    <span class="app-maintenance-message-dismiss fas fa-times"
                          v-on:click="dismissMaintenanceMessage()">
                    </span>
                    <div v-dompurify-html="$tc('generals.sfMaintenanceMessage')"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="delayedTaskConfig"
                 class="stop-task-termination-wrapper">
                <div class="stop-task-termination-container">
                    <div class="stop-task-termination-timer"
                         v-bind:class="{
                             'm--running': delayedTaskConfig.secondsLeft <= 8,
                             'm--warning': delayedTaskConfig.secondsLeft <= 5 && delayedTaskConfig.secondsLeft >= 3,
                             'm--error': delayedTaskConfig.secondsLeft <= 2
                         }"
                         v-bind:style="{width: delayedTaskConfig.secondsLeft*10 + '%'}">
                    </div>
                    <div>{{$tc('generals.stopTaskTermination.info')}}</div>
                    <Button class="stop-task-termination-button"
                            button-type="submit"
                            @button-submit="stopTaskTermination()">
                        <template v-slot>
                            <span>{{$tc('generals.stopTaskTermination.buttonText')}}</span>
                        </template>
                    </Button>
                </div>
            </div>
        </transition>
        <MainNavigation></MainNavigation>
        <div class="app-content-wrapper">
            <router-view></router-view>
        </div>
    </div>
</template>

<style scoped lang="less">
.app {
    width: 100%;
    height: 100%;

    &.m--styled-scrollbar {
        ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: var(--color-background-mid);
            border-radius: 8px;

            &:hover {
                background: var(--color-background-highlighted);
            }
        }
    }

    .app-maintenance-message-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: var(--z-index-notification);
        padding-top: 20px;

        .app-maintenance-message-container {
            position: relative;
            width: 80%;
            padding: 0 var(--container-spacing) var(--container-spacing) var(--container-spacing);
            display: block;
            margin: 0 auto;
            background-color: var(--color-background-default);
            border: 2px solid var(--color-warning);
            border-left: 20px solid var(--color-warning);
            box-shadow: 0 6px 10px 0 var(--color-border-dark);
            z-index: var(--z-index-notification);

            .app-maintenance-message-dismiss {
                position: absolute;
                right: 20px;
                top: 16px;
                color: var(--color-text-mid);

                &:hover {
                    cursor: pointer;
                    color: var(--color-text-highlighted);
                }
            }
        }
    }

    .stop-task-termination-wrapper {
        position: absolute;
        bottom: 10%;
        width: 100%;
        z-index: var(--z-index-notification);

        .stop-task-termination-container {
            position: relative;
            width: 300px;
            border-radius: 8px;
            background-color: var(--color-background-light);
            margin: auto;
            padding: var(--container-spacing);
            text-align: center;
            box-shadow: 0 5px 10px 0 var(--color-border-dark);

            .stop-task-termination-timer {
                position: absolute;
                width: 100%;
                height: 6px;
                top: 0;
                left: 0;
                margin-bottom: 8px;
                border-radius: 8px 8px 0 0;
                background-color: var(--color-background-highlighted);
                transition: width 1s linear, background-color 1s linear, border-radius 1s linear;

                &.m--warning {
                    background-color: var(--color-warning);
                }

                &.m--error {
                    background-color: var(--color-error);
                }

                &.m--running {
                    border-radius: 8px 8px 8px 0;
                }
            }

            .stop-task-termination-button {
                margin: 20px 0 5px 0;
            }
        }
    }

    .app-content-wrapper {
        position: absolute;
        width: 100%;
        height: calc(100% - var(--main-navigation-height));
        overflow: hidden;
        outline: none;
    }
}
</style>
