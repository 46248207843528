<script>
/**
 * CursorNotification is an element that displays a message close to cursor, so that the user will always be aware of errors and success messages.
 * CursorNotification is a global element controlled by the mixin notificationHandler.
 * There is no need to add additional notifications in the application. Just push objects containing a message, duration and type to the notification queue by calling this.addNotification(notificationObject).
 * You'll have to add the mixin notificationHandler to your component or view to access the function.
 * The notification will when the time in duration runs out. It emits a @notification-vanished event.
 * - id (required): id of the notification. Necessary for calculating the position of the element.
 * - message (required): The message displayed in the notification.
 * - duration (default: 5000): The time in milliseconds until the notification disappears.
 * - type (default: info): The message type. The color of the message border will change based on the type. Possible values are: info, success, warning, error.
 **/
export default {
    name: 'Notification',
    // @notification-vanished: Event emitted on vanish | returns the id of the notification
    emits: ['notification-vanished'],
    props: {
        id: [String, Number],
        message: String,
        duration: {
            default: 3000,
            type: Number
        },
        type: {
            default: 'info',
            type: String
        }
    },
    data () {
        return {
            notificationVisible: false
        }
    },
    methods: {
        emitVanished () {
            this.$emit('notification-vanished', this.id)
        }
    },
    computed: {
        getNotificationIcon () {
            switch (this.type) {
            case 'success':
                return 'fas fa-check-circle'
            case 'warning':
                return 'fas fa-exclamation-circle'
            case 'error':
                return 'fas fa-minus-circle'
            default:
                return 'fas fa-info-circle'
            }
        },

        calculatePosition () {
            const notifications = Array.from(document.getElementsByClassName('cursor-notification'))
            let positionX = this.$global.mousePosition.x - 5
            let positionY = this.$global.mousePosition.y + 25
            positionY += 30 * notifications.filter(notification => notification.id < this.id).length

            if (positionX > window.innerWidth * 0.90) {
                const cursorNotification = document.getElementById(this.id)
                if (cursorNotification) {
                    positionX -= cursorNotification.getBoundingClientRect().width - 20
                }
            }

            return { transform: `translate3d(${positionX}px, ${positionY}px, 0px)` }
        }
    },
    mounted () {
        setTimeout(() => {
            this.emitVanished()
            this.notificationVisible = false
        }, this.duration)
        this.notificationVisible = true
    }
}
</script>

<template>
    <transition name="fade">
        <div v-if="notificationVisible"
             v-bind:id="id"
             v-bind:class="`cursor-notification m--${type}`"
             v-bind:style="calculatePosition">
            <div class="cursor-notification-icon">
                <span v-bind:class="getNotificationIcon"></span>
            </div>
            <span class="cursor-notification-message">{{message || $tc('notification.defaultMessage')}}</span>
        </div>
    </transition>
</template>

<style lang="less">
.cursor-notification {
    position: absolute;
    height: var(--cursor-notification-height);
    width: auto;
    border: 1px solid;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 13px;
    z-index: var(--z-index-notification);
    background-color: var(--color-background-default);
    pointer-events: none;

    &.m--info {
        border-color: var(--color-info);
        color: var(--color-info);
    }

    &.m--success {
        border-color: var(--color-success);
        color: var(--color-success);
    }

    &.m--warning {
        border-color: var(--color-warning);
        color: var(--color-warning);
    }

    &.m--error {
        border-color: var(--color-error);
        color: var(--color-error);
    }

    .cursor-notification-icon {
        display: inline;
        margin-right: 6px;
    }

    .cursor-notification-message {
        color: var(--color-text-mid);
    }
}
</style>
