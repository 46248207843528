<script>
/**
 * Notification is an element that displays a message in the top right or bottom right corner of the screen. Notification is a global element controlled by the mixin notificationHandler.
 * There is no need to add additional notifications in the application. Just push objects containing a message, duration and type to the notification queue by calling this.addNotification(notificationObject).
 * You'll have to add the mixin notificationHandler to your component or view to access the function.
 * The notification will vanish on click or when the time in duration runs out. It emits a @notification-vanished event.
 * - id (required): id of the notification. Necessary for calculating the position of the element. Will be passed by the notificationHandler!
 * - message (required): The message displayed in the notification.
 * - duration (default: 5000): The time in milliseconds until the notification disappears.
 * - type (default: info): The message type. The color of the message border will change based on the type. Possible values are: info, success, warning, error.
 * - placement (default: top): Determines whether the message will be placed at the top right or bottom right corner of the screen. Will be passed by the notificationHandler!
 **/
export default {
    name: 'Notification',
    // @notification-vanished: Event emitted on vanish | returns the id of the notification
    emits: ['notification-vanished'],
    props: {
        id: [String, Number],
        message: String,
        duration: {
            default: 5000,
            type: Number
        },
        type: {
            default: 'info',
            type: String
        },
        placement: {
            default: 'top',
            type: String
        }
    },
    data () {
        return {
            notificationVisible: false,
            messageVisible: false,
            defaultFrameDistance: 15 // This should be the same as the value of --notification-frame-distance
        }
    },
    methods: {
        recalculatePositionsOfRemainingNotifications () {
            const vanishingElement = document.getElementById(this.id.toString())
            if (vanishingElement) {
                let notifications = Array.from(document.getElementsByClassName('notification'))
                notifications = notifications.filter(notification => notification.id > this.id)
                const distanceToRemove = vanishingElement.clientHeight + this.defaultFrameDistance

                notifications.forEach(notification => {
                    const boundingStyles = notification.getBoundingClientRect()
                    const newValue = (boundingStyles[this.placement] - distanceToRemove)
                    notification.style[this.placement] = `${newValue}px`
                })
            }
        },

        changeVisibility (show) {
            if (show) {
                this.notificationVisible = true
                setTimeout(() => {
                    this.messageVisible = true
                }, 100)
            } else {
                clearTimeout(this.vanishingTimer)
                this.messageVisible = false
                setTimeout(() => {
                    this.notificationVisible = false
                    this.recalculatePositionsOfRemainingNotifications()
                    this.emitVanished()
                }, 900)
            }
        },

        emitVanished () {
            this.$emit('notification-vanished', this.id)
        }
    },
    computed: {
        calculateInitialPosition () {
            let position = this.defaultFrameDistance
            let notifications = Array.from(document.getElementsByClassName('notification'))
            notifications = notifications.filter(notification => notification.id < this.id)

            notifications.forEach(notification => {
                position += notification.clientHeight + this.defaultFrameDistance
            })

            return this.placement === 'top'
                ? { top: position + 'px' }
                : { bottom: position + 'px' }
        },

        isVisible () {
            return this.notificationVisible
        },

        getNotificationIcon () {
            switch (this.type) {
            case 'success':
                return 'fas fa-check-circle'
            case 'warning':
                return 'fas fa-exclamation-circle'
            case 'error':
                return 'fas fa-minus-circle'
            default:
                return 'fas fa-info-circle'
            }
        },

        getNotificationHeadline () {
            return this.$tc(`notification.headlines.${this.type}`)
        }
    },
    mounted () {
        this.changeVisibility(true)
        this.vanishingTimer = setTimeout(() => {
            this.changeVisibility(false)
        }, this.duration)
    }
}
</script>

<template>
    <div v-if="isVisible"
         v-bind:id="id"
         class="notification generals-animate"
         v-bind:class="['m--' + type, {'m--visible': messageVisible}]"
         v-bind:style="calculateInitialPosition"
         v-on:click="changeVisibility(false)">
        <div class="notification-icon">
            <span v-bind:class="getNotificationIcon"></span>
        </div>
        <div class="notification-heading">
            <span>{{getNotificationHeadline}}</span>
        </div>
        <div v-dompurify-html="message || $tc('notification.defaultMessage')"></div>
    </div>
</template>

<style lang="less">
.notification {
    position: absolute;
    min-height: var(--notification-min-height);
    height: auto;
    width: var(--notification-width);
    background-color: var(--color-background-default);
    border: 1px solid;
    border-left: 25px solid;
    border-radius: 0;
    padding: var(--container-spacing);
    z-index: var(--z-index-notification);
    right: -500px;
    line-height: 22px;
    cursor: pointer;

    &.m--visible {
        right: var(--notification-frame-distance);
    }

    &.m--info {
        border-color: var(--color-info);

        .notification-heading {
            color: var(--color-info);
        }
    }

    &.m--success {
        border-color: var(--color-success);

        .notification-heading {
            color: var(--color-success);
        }
    }

    &.m--warning {
        border-color: var(--color-warning);

        .notification-heading {
            color: var(--color-warning);
        }
    }

    &.m--error {
        border-color: var(--color-error);

        .notification-heading {
            color: var(--color-error);
        }
    }

    .notification-icon {
        position: absolute;
        font-size: 17px;
        left: -21px;
        top: 50%;
        color: var(--color-text-bright);
        transform: translateY(-50%);
    }

    .notification-heading {
        font-family: "Source Sans Pro Bold", sans-serif;
        margin: -4px 0 4px 0;
    }
}
</style>
