<script>
/**
 * Button is a pre styled button element.
 * It will throw a @button-submit event on click.
 * - buttonType (default: submit): The type defines how the button will look like. Possible values are: submit (highlighted button, filled), caution (like submit, but red) cancel (transparent background with border), inline (transparent background and borders)
 * - isDisabled (default: false): If true, the button can't be clicked.
 **/
export default {
    name: 'Button',
    // @button-submit: Event emitted on click | returns the event
    emits: ['button-submit'],
    props: {
        buttonType: {
            default: 'submit',
            type: String
        },
        isDisabled: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        emitSubmit (event) {
            event.stopPropagation()
            this.$emit('button-submit', event)
        }
    }
}
</script>

<template>
    <button class="c_button"
            v-bind:class="{['m--' + buttonType]: buttonType, 'm--disabled': isDisabled}"
            v-on:click="emitSubmit($event)">
        <slot>
            <span>{{$tc('button.defaultText')}}</span>
        </slot>
    </button>
</template>

<style lang="less">
.c_button {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;
    min-width: 90px;
    height: var(--button-height);
    padding: 0 16px;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    outline: none;

    &.m--submit {
        background-color: var(--color-button-with-background);
        color:var(--color-text-bright);

        &:hover {
            background-color: var(--color-button-with-background-active);
            color:var(--color-text-bright)
        }

        &.m--disabled {
            background-color: var(--color-button-disabled);
            color: var(--color-text-bright);
            pointer-events: none;
        }
    }

    &.m--caution {
        background-color: var(--color-button-with-caution-background);
        color:var(--color-text-bright);

        &:hover {
            background-color: var(--color-button-with-caution-background-active);
            color:var(--color-text-bright)
        }

        &.m--disabled {
            background-color: var(--color-button-disabled);
            color: var(--color-text-bright);
            pointer-events: none;
        }
    }

    &.m--cancel {
        background-color: var(--color-background-default);
        border: 1px solid var(--color-text-mid);
        color: var(--color-text-mid);

        &:hover {
            color: var(--color-button-with-background);
            border-color: var(--color-button-with-background);
        }

        &.m--disabled {
            border-color: var(--color-button-disabled);
            color: var(--color-button-disabled);
            pointer-events: none;
        }
    }

    &.m--inline {
        background-color: transparent;
        color: var(--color-text-mid);

        &.m--disabled {
            pointer-events: none;
            color: var(--color-button-disabled);
        }

        &:hover {
            color: var(--color-button-with-background);
        }
    }
}
</style>
