<script>
/**
 * A static page shown, if a user has no rights to access CorFlow app.
 **/

// @ is an alias to /src
import ContentHeader from '@/components/ContentHeader.vue'
import Button from '@/components/Button.vue'
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'NoAccess',
    components: {
        ContentHeader,
        Button,
        Accordion
    },
    data () {
        return {
            languages: {
                german: 'de',
                english: 'en'
            }
        }
    },
    methods: {
        switchLanguage () {
            this.$i18n.locale = this.$i18n.locale === this.languages.german
                ? this.languages.english
                : this.languages.german
        },

        openMicrosoftLogin () {
            window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout'
        },

        openServicePortal () {
            window.open('https://hilfe.cornelsen.de/wm/app-SelfServicePortal', '_blank')
        }
    },
    computed: {
        getAppLogoUrl () {
            document.title = `CorFlow - ${this.$tc('noAccess.pageTitle')}`
            return 'img/cornelsen-production.png'
        }
    }
}
</script>

<template>
    <div id="app"
         class="app">
        <header class="no-access-header">
            <img alt="Cornelsen - CorFlow"
                 class="no-access-header-logo"
                 v-bind:src="getAppLogoUrl" />
            <div class="no-access-header-profile">
                <span class="fas fa-user"></span>
                <span class="fas fa-ban no-access-header-ban"></span>
            </div>
            <div class="no-access-header-addons">
                <span class="no-access-header-language-switch"
                      v-bind:title="$tc('noAccess.languageTooltip')"
                      v-on:click="switchLanguage()">{{$tc('noAccess.language')}}</span>
            </div>
        </header>
        <ContentHeader v-bind:is-primary-header="true"
                       v-bind:header-title="$tc('noAccess.headerTitle')"
                       v-bind:header-sub-title="$tc('noAccess.headerSubtitle')">
        </ContentHeader>
        <div class="no-access-body">
            <div class="no-access-body-content-container">
                <div class="no-access-body-content-headline">{{$tc('noAccess.howToGetAccess')}}</div>
                <div class="no-access-body-content">{{$tc('noAccess.checkLogin')}}</div>
                <div class="no-access-redirect">
                    <Button button-type="submit"
                            @button-submit="openMicrosoftLogin()">
                        <template v-slot>
                            <span>{{$tc('noAccess.toMicrosoftLogin')}}</span>
                        </template>
                    </Button>
                </div>
            </div>
            <div class="no-access-body-content-container">
                <div class="no-access-body-content-headline">{{$tc('noAccess.stillNoAccess')}}</div>
                <div class="no-access-body-content">{{$tc('noAccess.furtherActions')}}</div>
                <Accordion class="no-access-accordion"
                           v-bind:title="$tc('noAccess.internalEmployee')"
                           v-bind:use-custom-content="true">
                    <template v-slot>
                        <div key="internalText"
                             class="no-access-accordion-content">
                            <span>{{$tc('noAccess.internalEmployeeHelp')}}</span>
                        </div>
                        <div key="internalButton"
                             class="no-access-redirect">
                            <Button button-type="submit"
                                    @button-submit="openServicePortal()">
                                <template v-slot>
                                    <span>{{$tc('noAccess.toServicePortal')}}</span>
                                </template>
                            </Button>
                        </div>
                    </template>
                </Accordion>
                <Accordion class="no-access-accordion"
                           v-bind:title="$tc('noAccess.externalServiceProvider')"
                           v-bind:use-custom-content="true"
                           v-bind:expanded-by-default="false">
                    <template v-slot>
                        <div key="externalText"
                             class="no-access-accordion-content">
                            <span>{{$tc('noAccess.externalServiceProviderHelp')}}</span>
                        </div>
                    </template>
                </Accordion>
            </div>
            <div class="no-access-body-content-container">
                <div class="no-access-body-content m--no-headline"
                     v-dompurify-html="$t('noAccess.conclusion')">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .no-access-header {
        width: 100%;
        height: var(--main-navigation-height);
        padding: 5px var(--container-spacing);
        border-bottom: 2px solid var(--color-border-light);
        font-size: 16px;

        .no-access-header-logo {
            width: auto;
            height: 45px;
            margin: 4.5px var(--container-spacing) 0 0;
        }

        .no-access-header-addons {
            float: right;
            padding-top: 1px;
            margin: 16px 40px 16px 0;

            .no-access-header-language-switch {
                cursor: pointer;

                &:hover {
                    color: var(--color-text-highlighted);
                }
            }
        }

        .no-access-header-profile {
            position: relative;
            float: right;
            height: var(--main-navigation-line-height);
            width: var(--main-navigation-line-height);
            border-radius: 50%;
            border: 2px solid transparent;
            color: var(--color-text-mid);
            font-size: 24px;
            text-transform: uppercase;
            padding-top: 7px;
            text-align: center;
            margin-top: 2px;

            .no-access-header-ban {
                color: var(--color-error);
                position: absolute;
                left: -3px;
                top: -3px;
                font-size: 52px;
                opacity: 0.5;
            }
        }
    }

    .no-access-body {
        position: relative;
        width: 100%;
        height: calc(100% - var(--main-navigation-height) - var(--primary-headline-height));
        padding: 0 40px 40px 40px;
        overflow-y: auto;

        .no-access-body-content-container {
            max-width: 750px;
            margin: 0 auto;
            text-align: justify;
            border-bottom: 1px solid var(--color-border-light);

            &:last-child {
                border-bottom: 0;
            }

            .no-access-body-content-headline {
                font-family: "Source Sans Pro Bold", sans-serif;
                font-size: 25px;
                margin-top: 40px;
                text-align: center;
            }

            .no-access-body-content {
                margin: 25px 0;
                text-align: justify;

                &:last-child {
                    margin: 40px 0 0 0;
                }
            }

            .no-access-redirect {
                margin: 0 auto 25px auto;
                width: 200px;
            }

            .no-access-accordion {

                &:last-child {
                    margin-bottom: 25px;
                }

                .no-access-accordion-content {
                    width: 100%;
                    padding: var(--container-spacing);
                }
            }
        }
    }
}
</style>
