import { regexHelper } from '@/mixins/regexHelper'

export const urlHelper = {
    methods: {
        isUrlValue (value) {
            return regexHelper.methods.isValidUrl(value) || regexHelper.methods.isValidUrl('https://' + value)
        },
        openUrlForValue (value) {
            if (regexHelper.methods.isValidUrl(value)) {
                return window.open(value, '_blank')
            } else if (regexHelper.methods.isValidUrl(`https://${value}`)) {
                return window.open(`https://${value}`, '_blank')
            }
            // else do nothing - don't call this function if it's not a URL (use isValidUrl as a guard condition)
        },
        toQueryParameters (keyValueMap) {
            return new URLSearchParams(keyValueMap).toString()
        }
    }
}
